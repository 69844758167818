import { Flex, Image, Link, Text } from "@chakra-ui/react";
import ColLogo from "./assets/navbar/coliseum-logo.svg";
import ColText from "./assets/navbar/coliseum-text.png";
import "./App.css";
import { socials, SocialIconSvg } from "./lib/socials";
import { ethers } from "ethers";
import React, { useState } from 'react';
import MintPage from './MintPage.jsx'

const NavBarMint = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);
  const [address, setAddress] = useState("");

  async function connectAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      setAccounts(accounts);
      setAddress(address)
      console.log("Address log:")
      console.log(address)  
    }
  }
  // let navigate = useNavigate()

  return (
    <Flex
      as={"nav"}
      justify="space-between"
      align="center"
      maxWidth={"1700px"}
      margin={"0 auto"}
      padding={"1rem 1.5rem"}
    >
      {/* Left Side - Social Media */}
      <Flex alignItems={"center"} basis={"40%"} flex={"1 1 0%"} gap={"1rem"}>
        <Image src={ColLogo} boxSize="56px" />
        <Flex
          as="ul"
          gap={"0.625rem"}
          borderLeft={"1px solid"}
          borderColor={"#44403c"}
          paddingLeft={"1rem"}
          paddingY={"0.5rem"}
          display={{ base: "none", lg: "flex" }}
        >
          {socials.map((social) => (
            <li key={social.name}>
              {social.disabled ? (
                <Flex>
                  <span style={{ color: "#44403c" }}>
                    <SocialIconSvg name={social.name} icon={social.icon} />
                  </span>
                </Flex>
              ) : (
                <Link
                  display={"flex"}
                  href={social.link}
                  isExternal
                  style={{ color: "#c7ab55" }}
                >
                  <SocialIconSvg name={social.name} icon={social.icon} />
                  <span className="sr-only">{social.name}</span>
                </Link>
              )}
            </li>
          ))}
        </Flex>
      </Flex>
      <Flex
        basis={"20%"}
        flexDir={"column"}
        gap={"0.125rem"}
        alignItems="center"
        display={{ base: "none", md: "flex" }}
      >
        <Image src={ColText} fit={"contain"} width={["8rem", "12rem"]} />
        <Text
          fontSize={{ base: "0.75rem", xl: "1.25rem" }}
          whiteSpace={"nowrap"}
          textColor={"#a8a29e"}
          m={0}
          fontFamily={"serif"}
          lineHeight={{ base: "1.25rem", lg: "1.5rem" }}
        >
          The Only Access Pass You Need
        </Text>
      </Flex>
      <Flex align="center" justifyContent={"end"} basis={"40%"}>
        <button
          style={{
            borderRadius: "1rem",
            backgroundColor: "#c7ab55",
            fontSize: "1.5rem",
            padding: "0.375rem 1.5rem",
            lineHeight: "1.75rem",
            fontWeight: "700",
            cursor: "pointer",
          }}
          className="font-cinzel"
          type="button"
          onClick={() => connectAccount()}
        >
          {isConnected ? "Connected" : "Connect"}
        </button>
      </Flex>
    </Flex>
  );
};

export default NavBarMint;
