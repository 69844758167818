import { ethers } from "ethers";
import Reservations from "./Reservations.json";
import {
  Box,
  Flex,
  Text,
  Image,
  Container,
  Heading,
  Button,
} from "@chakra-ui/react";
import ColLogo from "./assets/mainContent/ColLogo.png";
import "./App.css";
import NavBarMint from "./NavBarMint.jsx";
import isAllowlistedTier2 from "./Tier2List.js";
import getProofTier2 from "./Tier2ListProof.js";
import SwordsIcon from "./lib/swords";
import React, { useState, useEffect } from 'react';

const ReservationsAddress = "0xcE7f5ABB916b2fcC9505f58aA988cA0C4ED6E34B";

const MintPage = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);

  const [Reserve, setReserve] = useState("Reserve");

  async function connectAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(accounts);
    }
  }

  async function handleMint() {
    await HandleNetworkSwitch();

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const contract = new ethers.Contract(
        ReservationsAddress,
        Reservations.result,
        provider.getSigner()
      );
      // Check the signer's balance
      const balance = await provider.getSigner().getBalance();

      // Compare the balance with the desired minimum amount
      if (balance.lt(ethers.utils.parseEther("0.5"))) {
        //
        console.error(
          "The signer doesn't have enough ETH to complete this transaction."
        );
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "white";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "You need 0.5E to reserve.";

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

        return;
      }
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      console.log(await signer.getAddress());
      console.log("PASS I");

      if (
        (await isAllowlistedTier2(address)) == false &&
        (await isAllowlistedTier2(address.toLowerCase())) == false
      ) {
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "white";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "You are not eligible to reserve.";

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

        return;
      }
      const proof = await getProofTier2(address.toString())
      if (proof === false) {
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "white";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "Failed to generate proof.";

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

        return;
      }
      try {
        const response = await contract.reserve(proof, {
          value: ethers.utils.parseEther("0.5"),
        });
        console.log("response: ", response);
        // create the notification element
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "SUCCESS!";

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);
        await response.wait();

        setReserve("Reserved!")

        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "RESERVED!";

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

      } catch (err) {
        console.log("error: ", err);
        
          // create the notification element
          var notification = document.createElement("div");
          notification.style.position = "fixed";
          notification.style.top = "10px";
          notification.style.right = "55px";
          notification.style.padding = "10px";
          notification.style.backgroundColor = "#c7ab55";
          notification.style.color = "white";
          notification.style.borderRadius = "5px";
          notification.style.fontFamily = "Cinzel Decorative";
          notification.style.marginTop = "5%";
          notification.style.fontWeight = "bold";
          notification.classList.add("notification-animation");
          notification.style.marginLeft = "8%";

          document.body.appendChild(notification);

          // create the close button
          var closeButton = document.createElement("button");
          closeButton.style.float = "right";
          closeButton.innerText = "x";
          notification.appendChild(closeButton);

          // set the notification text
          if (err.message) {
            notification.innerText = "FAIL!";
          } else {
            notification.innerText = "FAIL!";
          }

          // add a click event listener to the close button
          closeButton.addEventListener("click", function () {
            notification.style.display = "none";
          });
          setTimeout(function () {
            notification.style.display = "none";
          }, 3000);
        
          setReserve("Reserved!")
      }
    }
  }

  return (
    <>
      <NavBarMint accounts={accounts} setAccounts={setAccounts} />
      <Container
        as="main"
        maxW={"1700px"}
        centerContent
        mx={"auto"}
        mt={{ base: "1rem", lg: "6rem" }}
      >
        <Image
          src={ColLogo}
          align="center"
          boxSize={{ base: "300px", md: "400px" }}
        />
        <Box
          color={"#e7e5e4"}
          className="font-cinzel"
          bgColor={"#0b0d12"}
          px={"2.5rem"}
          py={"1rem"}
          rounded={"1rem"}
          my={"1.5rem"}
          border={"1px solid #c7ab55"}
        >
          <Heading
            fontSize={{ md: "1.5rem" }}
            className="font-cinzel"
            my={"0.25rem"}
          >
            Normal Reservation
          </Heading>
          <Text
            fontWeight={"bold"}
            my={0}
            fontSize={{ base: "1.5rem", md: "2rem" }}
            lineHeight={"2rem"}
          >
            0.5 ETH
          </Text>
        </Box>
        <Flex justify="center" align="top">
          {isConnected ? (

            <Button
              type="button"
              className="font-cinzel"
              style={{ backgroundColor: "#c7ab55" }}
              onClick={handleMint}
              fontSize={"1.75rem"}
              fontWeight={"bold"}
              px={"1.5rem"}
              py={"0.25rem"}
              rounded={"1rem"}
              gap={"0.25rem"}
            >
              <SwordsIcon />
              {Reserve}
            </Button>
          ) : (
            <Text
              align=""
              fontSize="1.75rem"
              className="ConnectToAdjust"
              fontFamily="Cinzel Decorative"
              color="white"
            >
              Connect To Proceed
            </Text>
          )}
        </Flex>
      </Container>
    </>
  );
};
export default MintPage;

async function HandleNetworkSwitch() {

  try {
      if (!window.ethereum) throw new Error("No crypto wallet found");

      if (window.ethereum.networkVersion !== 1) {

          try {
              await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: "0x1" }]
              });

          } catch (err) {
              // This error code indicates that the chain has not been added to MetaMask
              if (err.code === 4902) {
                  await window.ethereum.request({
                      method: 'wallet_addEthereumChain',
                      params: [
                          {
                              ...networks['homestead']
                          }]
                  });
              }
          }
      }
  } catch (err) {
      console.log(err.message);
  }
}

const networks = {
  homestead: {
      chainId: `0x${Number(1).toString(16)}`,
      chainName: "Ethereum Mainnet",
      nativeCurrency: {
          name: "Ether",
          symbol: "ETH",
          decimals: 18
      },
      rpcUrls: ["https://api.mycryptoapi.com/eth/"],
      blockExplorerUrls: ["https://etherscan.io/"]
  }
};

/**
 * 
 * 
 *        <Image 
        src="path/to/allowlisted-image.jpg"
        isHidden={!isAllowlisted(accounts[0])}
      />
MAINNET: 
async function HandleNetworkSwitch() {

    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== 1) {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x1" }]
                });

            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['homestead']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    homestead: {
        chainId: `0x${Number(1).toString(16)}`,
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://api.mycryptoapi.com/eth/"],
        blockExplorerUrls: ["https://etherscan.io/"]
    }
};
GÖRLI:
async function HandleNetworkSwitch() {
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== 5) {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x5" }]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['goerli']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    goerli: {
        chainId: `0x${Number(5).toString(16)}`,
        chainName: "Goerli",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://goerli.infura.io/v3/YOUR-PROJECT-ID"],
        blockExplorerUrls: ["https://goerli.etherscan.io/"]
    }
};

 */