import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
const dotenv = require("dotenv");
const MerkleTree = require("merkletreejs").MerkleTree;
const keccak256 = require("keccak256");

async function getProofTier2(address) {
    const firebaseConfig = await {
        apiKey: (process.env.REACT_APP_API_KEY).toString(),
        authDomain: (process.env.REACT_APP_AUTH_DOMAIN).toString(),
        databaseURL: (process.env.REACT_APP_DATABASEURL).toString(),
        projectId: "",
        storageBucket: "",
        messagingSenderId: "",
        appId: ""
    };
    const app = initializeApp(firebaseConfig);

    const database = getDatabase(app);

    const dbRef = ref(getDatabase());
    let setProof = false;
    try {
        await get(child(dbRef, `/`)).then((snapshot) => {

            if (snapshot.exists()) {

                const leafNodes = snapshot.val().map(addr => keccak256(addr.toLowerCase().trim()));

                const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
                let rootHash = merkleTree.getRoot().toString('hex')
                console.log('root', rootHash)
                console.log("***********")
                console.log("address: ", address)
                let hashedAddress = keccak256(address)
                const proof = merkleTree.getHexProof(hashedAddress);
                let verified = merkleTree.verify(proof, hashedAddress, rootHash)
                console.log("verified", verified)
                console.log("proof", proof)
                setProof = proof
                return proof

            } else {
                console.log("No data available");
                setProof = false
                return false

            }
        }).catch((error) => {
            console.error(error);
        });
    } catch (err) {
        console.log("Something went wrong")
    }
    return setProof
}

const allowlistAddresses = [
    "0x5257c686F5F98AbCC4Ba001Bf1347662984f72eF",
    "0xfDf3812C4Ab30A5A8f6b24069dc9b8Ab5D7d6686",
    "0x82015A57FCAb84099b668A8BAA7d2Fe453915Ea3"
]

const leafNodes = allowlistAddresses.map(addr => keccak256(addr.toLowerCase().trim()));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
let rootHash = merkleTree.getRoot().toString('hex')


export default getProofTier2;