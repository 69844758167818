const SwordsIcon = (props) => (
  <svg
    width={32}
    height={32}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="currentColor"
    {...props}
  >
    <g>
      <g>
        <g id="XMLID_1_">
          <path
            d="M206,359.23c0.8,0.79,1.23,1.85,1.23,2.99c0,1.14-0.43,2.2-1.23,3l-8.25,8.24c-0.83,0.83-1.88,1.26-2.97,1.26
				c-0.7,0-1.41-0.17-2.08-0.54l-32.5-18.02c-0.1-0.07-0.2-0.13-0.32-0.18l-19.16-10.62l-10.36-18.78l-18.09-32.79
				c-0.95-1.72-0.68-3.64,0.72-5.04l8.25-8.26c1.66-1.65,4.34-1.65,6,0L206,359.23z"
          />
          <path
            d="M134.49,353.57l14.71,8.17l-21.85,21.84l-24.45-24.45l21.76-21.76l7.83,14.21C132.95,352.41,133.64,353.11,134.49,353.57
				z"
          />
          <rect
            x="71.81"
            y="376.23"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -251.0385 180.9756)"
            width="42.25"
            height="34.58"
          />
          <path
            d="M58.54,403.47l24.45,24.45l-12.8,12.8c-5.64,5.64-14.1,6.35-18.87,1.58l-7.15-7.15c-4.77-4.77-4.06-13.23,1.58-18.87
				L58.54,403.47z"
          />
          <path
            d="M466.26,416.28c5.64,5.64,6.35,14.1,1.58,18.87l-7.15,7.15c-4.77,4.77-13.23,4.06-18.87-1.57L429,427.93l24.46-24.45
				L466.26,416.28z"
          />
          <polygon points="446.22,396.24 421.76,420.69 391.89,390.82 416.34,366.36 			" />
          <path d="M409.11,359.12l-24.45,24.46l-21.85-21.85l14.72-8.17c0.84-0.46,1.52-1.16,2-2l7.83-14.21L409.11,359.12z" />
          <path
            d="M384.75,280.47c0.83-0.83,1.91-1.24,3-1.24s2.17,0.41,3,1.24l8.25,8.26c1.39,1.39,1.67,3.32,0.72,5.04l-18.09,32.79
				l-10.36,18.78l-19.16,10.62c-0.11,0.05-0.21,0.11-0.32,0.18l-32.5,18.02c-1.7,0.94-3.68,0.66-5.06-0.72l-8.24-8.24
				c-1.65-1.66-1.65-4.34,0-5.99L384.75,280.47z"
          />
          <polygon points="334.52,316.23 322.82,327.93 263.66,274.65 279.05,260.78 			" />
          <polygon points="302,240.13 353.46,297.28 341.76,308.99 286.66,253.93 			" />
          <polygon points="189.18,327.93 177.48,316.23 420.05,73.79 416.23,123.49 			" />
          <polygon points="362.98,70.24 412.83,66.53 170.24,308.99 158.54,297.28 			" />
          <polygon points="225.67,207.44 209.25,225.67 95.77,123.49 91.95,73.79 			" />
          <polygon points="249.11,181.4 232.53,199.82 99.17,66.53 149.02,70.24 			" />
        </g>
      </g>
    </g>
  </svg>
);

export default SwordsIcon;
